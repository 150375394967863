"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateSupplyValidator = exports.updateSupplySchema = exports.createSupplyValidator = exports.createSupplySchema = exports.updateSaleValidator = exports.updateSaleSchema = exports.createSaleValidator = exports.createSaleSchema = exports.updateInventoryMovementValidator = exports.updateInventoryMovementSchema = exports.createInventoryMovementValidator = exports.createInventoryMovementSchema = exports.updateFinanceValidator = exports.updateFinanceSchema = exports.createFinanceValidator = exports.createFinanceSchema = void 0;
const Transaction_1 = require("../models/Transaction");
const yup_custom_1 = require("../yup-custom");
const validatorFinanceBase = yup_custom_1.yup.object().shape({
    date: yup_custom_1.yup.date().required(),
    type: yup_custom_1.yup.mixed().enum(Transaction_1.TransactionType).required(),
    observations: yup_custom_1.yup.string().nullable(),
    product: yup_custom_1.yup
        .object()
        .shape({
        id: yup_custom_1.yup.number().required(),
        price: yup_custom_1.yup.number().positive().required(),
        quantity: yup_custom_1.yup.number().positive().required(),
    })
        .required(),
});
exports.createFinanceSchema = validatorFinanceBase.concat(yup_custom_1.yup.object().shape({}));
const createFinanceValidator = async (body) => {
    return await exports.createFinanceSchema.validate(body, { stripUnknown: true });
};
exports.createFinanceValidator = createFinanceValidator;
exports.updateFinanceSchema = validatorFinanceBase.concat(yup_custom_1.yup.object().shape({}));
const updateFinanceValidator = async (body) => {
    return await exports.updateFinanceSchema.validate(body, { stripUnknown: true });
};
exports.updateFinanceValidator = updateFinanceValidator;
const validatorInventoryMovementBase = yup_custom_1.yup.object().shape({
    date: yup_custom_1.yup.date().required(),
    type: yup_custom_1.yup.mixed().enum(Transaction_1.TransactionType).required(),
    observations: yup_custom_1.yup.string().nullable(),
    products: yup_custom_1.yup
        .array()
        .of(yup_custom_1.yup.object().shape({
        id: yup_custom_1.yup.number().required(),
        size: yup_custom_1.yup.number().positive().required(),
        quantity: yup_custom_1.yup.number().positive().required(),
    }))
        .required()
        .min(1),
});
exports.createInventoryMovementSchema = validatorInventoryMovementBase.concat(yup_custom_1.yup.object().shape({}));
const createInventoryMovementValidator = async (body) => {
    return await exports.createInventoryMovementSchema.validate(body, {
        stripUnknown: true,
    });
};
exports.createInventoryMovementValidator = createInventoryMovementValidator;
exports.updateInventoryMovementSchema = validatorInventoryMovementBase.concat(yup_custom_1.yup.object().shape({}));
const updateInventoryMovementValidator = async (body) => {
    return await exports.updateInventoryMovementSchema.validate(body, {
        stripUnknown: true,
    });
};
exports.updateInventoryMovementValidator = updateInventoryMovementValidator;
const validatorSaleBase = yup_custom_1.yup.object().shape({
    partner: yup_custom_1.yup.string().nullable(),
    date: yup_custom_1.yup.date().required(),
    observations: yup_custom_1.yup.string().nullable(),
    products: yup_custom_1.yup
        .array()
        .of(yup_custom_1.yup.object().shape({
        id: yup_custom_1.yup.number().required(),
        removeIngredients: yup_custom_1.yup.boolean().required(),
        isResale: yup_custom_1.yup.boolean().required(),
        quantity: yup_custom_1.yup.number().positive().required(),
        unitSalePrice: yup_custom_1.yup.number().positive().required(),
    }))
        .required()
        .min(1),
});
exports.createSaleSchema = validatorSaleBase.concat(yup_custom_1.yup.object().shape({}));
const createSaleValidator = async (body) => {
    return await exports.createSaleSchema.validate(body, { stripUnknown: true });
};
exports.createSaleValidator = createSaleValidator;
exports.updateSaleSchema = validatorSaleBase.concat(yup_custom_1.yup.object().shape({
    products: yup_custom_1.yup
        .array()
        .of(yup_custom_1.yup.object().shape({
        id: yup_custom_1.yup.number().required(),
        removeIngredients: yup_custom_1.yup.boolean().required(),
        isResale: yup_custom_1.yup.boolean().required(),
        quantity: yup_custom_1.yup.number().positive().required(),
        unitSalePrice: yup_custom_1.yup.number().positive().required(),
        isNew: yup_custom_1.yup.boolean().required(),
    }))
        .required()
        .min(1),
}));
const updateSaleValidator = async (body) => {
    return await exports.updateSaleSchema.validate(body, { stripUnknown: true });
};
exports.updateSaleValidator = updateSaleValidator;
const validatorSupplyBase = yup_custom_1.yup.object().shape({
    date: yup_custom_1.yup.date().required(),
    observations: yup_custom_1.yup.string().nullable(),
    products: yup_custom_1.yup.array().of(yup_custom_1.yup.object().shape({
        id: yup_custom_1.yup.number().required(),
        size: yup_custom_1.yup.number().positive().required(),
        quantity: yup_custom_1.yup.number().positive().required(),
        price: yup_custom_1.yup.number().positive().required(),
    })),
});
exports.createSupplySchema = validatorSupplyBase.concat(yup_custom_1.yup.object().shape({
    nfcEId: yup_custom_1.yup.number(),
    products: yup_custom_1.yup.array().of(yup_custom_1.yup.object().shape({
        nfcEProductId: yup_custom_1.yup.number(),
        id: yup_custom_1.yup.number().required(),
        size: yup_custom_1.yup.number().positive().required(),
        quantity: yup_custom_1.yup.number().positive().required(),
        price: yup_custom_1.yup.number().positive().required(),
    })),
}));
const createSupplyValidator = async (body) => {
    return await exports.createSupplySchema.validate(body, { stripUnknown: true });
};
exports.createSupplyValidator = createSupplyValidator;
exports.updateSupplySchema = validatorSupplyBase.concat(yup_custom_1.yup.object().shape({}));
const updateSupplyValidator = async (body) => {
    return await exports.updateSupplySchema.validate(body, { stripUnknown: true });
};
exports.updateSupplyValidator = updateSupplyValidator;
